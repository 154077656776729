<template>
  <PixelWrapper>
    <div class="qon-page">
      <div class="qon-wrap">
        <nuxt />
      </div>
    </div>
  </PixelWrapper>
</template>

<script>
import PixelWrapper from '~/components/pixel_wrapper/PixelWrapper'
export default {
  name: 'LayoutsDefault',
  components: { PixelWrapper },
  middleware: ['auth', 'settings'],
  mounted() {
    this.getConfiguration()
  },
  methods: {
    getConfiguration() {
      this.$store.dispatch('layouts/getConfiguration')
    },
  },
}
</script>
