var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PixelWrapper',[(!_vm.isInitiated && _vm.$auth.loggedIn)?_c('mp-box',{staticClass:"centered-item w-100 qon-loading"},[_c('mp-loader',{staticClass:"page-loader"})],1):_c('mp-box',{staticClass:"mp-body"},[(_vm.bannerMaintenance.isShow)?_c('BannerMaintenance',{attrs:{"banner-maintenance":_vm.bannerMaintenance || {}},on:{"set-banner-height":_vm.setBannerHeight}}):_vm._e(),_vm._v(" "),(_vm.bannerUserStatus.isShow)?_c('BannerUserStatus',{attrs:{"banner-user-status":_vm.bannerUserStatus,"billing-info":_vm.billing_info || {}},on:{"set-banner-height":_vm.setBannerHeight,"on-close-banner-status":_vm.onCloseBannerStatus,"redirect-to-customer-care":_vm.redirectToCustomerCare}}):_vm._e(),_vm._v(" "),(_vm.bannerCreditBalance.isShow)?_c('BannerCreditBalance',{attrs:{"is-chatbot":_vm.bannerCreditBalance.isChatbot,"banner-credit-balance":_vm.bannerCreditBalance || {},"billing-info":_vm.billing_info || {}},on:{"set-banner-height":_vm.setBannerHeight,"on-click-banner":_vm.onClickBanner}}):_vm._e(),_vm._v(" "),_c('mp-flex',{staticClass:"mp-wrapper",attrs:{"direction":"column","height":`calc(100vh - ${
        _vm.heightBannerMaintenance +
        _vm.heightBannerUserStatus +
        _vm.heightBannerCreditBalance
      }px)`}},[(_vm.user)?_c('HeaderComponent',{staticClass:"mp-header"}):_vm._e(),_vm._v(" "),_c('mp-flex',{staticClass:"mp-main-body",attrs:{"as":"main","flex":"1","overflow":"hidden"}},[(_vm.$auth.user)?_c('MekariSidebar',{staticClass:"mp-sidebar",attrs:{"banner-height-total":_vm.heightBannerMaintenance +
            _vm.heightBannerUserStatus +
            _vm.heightBannerCreditBalance,"menus":_vm.menuLists,"submenus":_vm.submenuLists,"has-sub-menu":_vm.submenuLists.length > 0,"is-custom":"","default-is-toggle":"","with-child":_vm.sidebarChildCondition()}}):_vm._e(),_vm._v(" "),_c('mp-box',{staticClass:"mp-content",attrs:{"as":"section","data-id":"content","width":"100%","overflow-y":_vm.isMpContentOverflowAuto ? 'auto' : 'hidden',"height":"100%"}},[_c('Nuxt'),_vm._v(" "),(_vm.$auth.user)?_c('InitComponent',{staticClass:"mp-init-component"}):_vm._e(),_vm._v(" "),_c('ModalFreeze'),_vm._v(" "),(
              _vm.feature_flag_state?.company_validation?.state &&
              (_vm.isAdmin() || _vm.isSupervisor())
            )?_c('ModalPICValidation'):_vm._e(),_vm._v(" "),(_vm.$auth.user)?_c('QontakCustomerCare',{staticClass:"mp-widget-customer-care",attrs:{"is-trial-user":_vm.billing_info.is_trial}}):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }