<template>
  <div class="nuxt-error">
    <component :is="errorPage" :error="error" />
  </div>
</template>

<script>
import Error404 from '~/components/errors/404'
import Error500 from '~/components/errors/500'
export default {
  name: 'LayoutsError',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    errorPage() {
      if (this.error.statusCode === 404) {
        return Error404
      }
      // catch everything else
      return Error500
    },
  },
}
</script>
