<template>
  <div class="qon-customer-page w-100">
    <div class="body-wrap">
      <Notification />
      <nuxt />
    </div>
  </div>
</template>

<script>
import Notification from '~/components/UI/Notification'
import '~/assets/stylesheets/base/fonts/_ gt_eesti.scss'

export default {
  name: 'LayoutsCustomer',
  components: {
    Notification,
  },
}
</script>

<style scoped>
.qon-customer-page {
  position: relative;
  min-height: 100vh;
  background-color: #f1f5f8;
}

.body-wrap {
  width: 100%;
  height: 100%;
}
</style>
